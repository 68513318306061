import React from 'react'

const Header = () => {
    return (
        <div className='header'>
       
            <marquee>
            <p>Dr. Vineet Agrawal Joint Replacement - Hip and Joint Replacement Surgeon in Lucknow ||  Emergency Helpline No. : +91 9506133392</p>
            </marquee>
       
    </div>
    )
}

export default Header