import React from 'react'
import { Link } from 'react-router-dom'
import about from './images/poster (4).jpg'

const About = () => {
    return (

        <>
            <div className='about'>
                <div className='about2'>

                    <h1>WELCOME TO  DR. VINEET AGRAWAL </h1>



                    <p>"Dr. Vineet Agrawal is an Orthopaedic Surgeon based in Lucknow, Uttar Pradesh, where he practises at his 100-bed hospital, the Avadh Hospital and Heart Centre, which he established in 1989. After obtaining his MBBS in 1981, Dr. Agrawal went on to complete his post-graduation (MS) in surgery in 1984, specialising in orthopaedics and trauma. He trained in trauma and joint replacement surgery at several esteemed institutions in India, and, with more than 25 years of experience behind him, he is highly experienced in all aspects of trauma, spine and joint replacement surgery, as well as spine disc listhesis. He started undertaking hip and knee replacements in 2002."</p>

                    <Link to='/Register'><button className="btn btn-primary">Contact US </button></Link>

                </div>
                <br />
                <div className='about2'>
                    <img src={about} />
                </div>
            </div>


            {/* <div className='emergncy'>
                <h1>OPEN 24*7  HRS [ EMERGENCY SERVICES ]</h1>
            </div> */}

            <div class="icon-bar">
                <a href="#" class="facebook"><i class="fa fa-facebook"></i></a>
                <a href="#" class="twitter"><i class="fa fa-instagram"></i></a>
                <a href="#" class="google"><i class="fa fa-whatsapp"></i></a>
                <a href="#" class="youtube"><i class="fa fa-youtube-play"></i></a>
            </div>


        </>
    )
}

export default About