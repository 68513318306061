import React from 'react'
import {Link} from 'react-router-dom';

const Treatment = () => {
    return (
        <>
            <br /><br />
           
            <center><h3>DR. VINEET AGRAWAL TREATMENT</h3></center>
            <div className='tratment'>
                <div className='treat2'>
                    <h1>Hip Resurfacing</h1>
                    <p>Hip resurfacing, also called surface replacement, is an alternative to total hip replacement that is appropriate for some patients. This operation preserves more of your natural bone than does a total hip replacement.</p>

                   <Link to='/Generlchp'> <button  className="btn btn-primary">Read More</button></Link>

                </div>
                <div className='treat2'>
                    <h1>Ligament Reconstruction</h1>
                    <p>ACL reconstruction is surgery to reconstruct the ligament in the center of your knee. The anterior cruciate ligament (ACL) connects your shin bone (tibia) to your thigh bone (femur). A tear of this ligament can cause your knee to give way during physical activity, most often during side-step or crossover movements. </p>
                    <Link to='/Generalsurgy'> <button  className="btn btn-primary">Read More</button></Link>
                </div>
                <div className='treat2'>
                    <h1>Arthroscopy</h1>
                    <p>Arthroscopy (ahr-THROS-kuh-pee) is a procedure for diagnosing and treating joint problems. A surgeon inserts a narrow tube attached to a fiber-optic video camera through a small incision — about the size of a buttonhole. </p>
                    <Link to='/LaparoscopicSurgery'> <button  className="btn btn-primary">Read More</button></Link>
                </div>
            </div>

            <div className='tratment'>
                <div className='treat2'>
                    <h1>Hip Replacement</h1>
                    <p>A surgical procedure in which an orthopaedic surgeon removes the diseased parts of the hip joint and replaces them with new, artificial parts. </p>
                    <Link to='/CancerSurgery'> <button  className="btn btn-primary">Read More</button></Link>
                </div>
                <div className='treat2'>
                    <h1>Shoulder Replacement</h1>
                    <p>Shoulder replacement surgery is a major operation, so you'll likely experience pain during your recovery. You might be given pain medications by injection right after your procedure.</p>
                    <Link to='/Chemotherapy'> <button  className="btn btn-primary">Read More</button></Link>
                </div>
                <div className='treat2'>
                    <h1>Knee Replacement</h1>
                    <p>For most people, knee replacement provides pain relief, improved mobility and a better quality of life. Most knee replacements can be expected to last at least 15 to 20 years.  </p>
                    <Link to='/Gastroenterology'> <button  className="btn btn-primary">Read More</button></Link>
                </div>
            </div>


            <div className='tratment'>
                <div className='treat2'>
                    <h1>Knee Osteotomy</h1>
                    <p>Osteotomy literally means "cutting of the bone." In a knee osteotomy, either the tibia (shinbone) or femur (thighbone) is cut and then reshaped to relieve pressure on one compartment of the knee joint. </p>
                    <Link to='/Maternity'> <button  className="btn btn-primary">Read More</button></Link>
                </div>
                <div className='treat2'>
                    <h1>Spinal Therapy</h1>
                    <p>Spinal decompression therapy involves stretching your spine using a manual or motorized traction table to help ease neck, back, or leg pain</p>
                    <Link to='/Gynaecology'> <button  className="btn btn-primary">Read More</button></Link>
                </div>
                <div className='treat2'>
                    <h1>Spinal Fusion</h1>
                    <p>Spinal fusion is a surgery procedure that reinforces your back structure and stability by linking two vertebrae together.</p>
                    <Link to='/Neurology'> <button  className="btn btn-primary">Read More</button></Link>
                </div>
            </div>

           

        </>
    )
}

export default Treatment