import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <>

            <div className='footer'>
                <div className='foot'>
                    <h1>Location</h1>
                    
                   

                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14242.34850835634!2d80.9105661!3d26.821272!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bfc3aa53536c3%3A0xaac37f796cca5281!2sDr.%20Vineet%20Agrawal%20Joint%20Replacement%20-%20Hip%20and%20Joint%20Replacement%20Surgeon%20in%20Lucknow%20%7C%7C!5e0!3m2!1sen!2sin!4v1719212405503!5m2!1sen!2sin"   ></iframe>
                </div>
                <div className='foot'>
                    <h1>Facility </h1>
                    
                    <Link to='' className='best'> <p>Advanced Technology</p></Link>
                    <Link to='' className='best'> <p>Highly Trained Staff Ortho</p></Link>
                    <Link to='' className='best'> <p>Plastic & Cosmetic Surgery</p></Link>
                    <Link to='' className='best'> <p>Medical Infrastructure</p></Link>


                </div>
                <div className='foot'>
                    <h1>Treatment</h1>
                    <Link to='/' className='best'> <p>Hip Resurfacing</p></Link>
                    <Link to='/' className='best'> <p>Ligament Reconstruction</p></Link>
                    <Link to='/' className='best'> <p>Arthroscopy</p></Link>
                    <Link to='/' className='best'> <p>Shoulder Replacement</p></Link>
                    <Link to='/' className='best'> <p>Spinal Therapy</p></Link>
                </div>
                <div className='foot'>
                    <h1>Reach Us </h1>
                    <p>
                        Doctor  Contact Number: +91 9506133392</p>
                   
                    <p>
                    Chamber:1, Patel Nagar, Adarsh Nagar, Alambagh, Lucknow, Uttar Pradesh 226005</p>

                    <p>agrawalvinet@gmail.com</p>

                  
                </div>
            </div>

            <div className='disclamer'>
                <p>@ 2024 DR. VINEET AGRAWAL  Designed by Creative Digital World </p>
            </div>


        </>
    )
}

export default Footer