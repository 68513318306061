import React from 'react'
import Footer from '../component/Footer'
import gal1 from "../component/images/poster (6).jpg"
import gal2 from "../component/images/screen (1).jpg"
import gal3 from "../component/images/servic (1).jpg"
import gal4 from "../component/images/servic (5).jpg"
import gal5 from "../component/images/servic (7).jpg"
import gal6 from "../component/images/servic (9).jpg"

const Gallery = () => {
  return (
    <>
      <div className='heading'>
        <h1>Home / Gallery </h1>
      </div>

      <div className='gallery'>

        <div className='gal'>
          <img src={gal1} />
        </div>
        <div className='gal'>
          <img src={gal2} />
        </div>
        <div className='gal'>
          <img src={gal3} />
        </div>
       

      </div>

      <div className='gallery'>

        <div className='gal'>
          <img src={gal4} />
        </div>
        <div className='gal'>
          <img src={gal5} />
        </div>
        <div className='gal'>
          <img src={gal6} />
        </div>
       

      </div>

      <Footer />
    </>
  )
}

export default Gallery