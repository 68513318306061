import React from 'react'
import { Carousel } from 'react-bootstrap';
import banner1 from './img/general-cheup.jpg';
import banner2 from './img/general-sugery.jpg';
import banner3 from './img/lapro-surgery.jpg';
import banner4 from './img/surgery-onco.jpg';
import banner5 from './img/neurology.jpg';

const Carousal = () => {
  return (
    <Carousel fade interval={3000} className="carousel-container">
      <Carousel.Item>
        <img className="d-block w-100" src={banner1} alt="General Checkup" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={banner2} alt="General Surgery" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={banner3} alt="Laparoscopic Surgery" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={banner4} alt="Cancer Surgery" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={banner5} alt="Neurology" />
      </Carousel.Item>
    </Carousel>
  )
}

export default Carousal