// App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js';
import Navbar from './component/Navbar';
import Home from './component/Home';
import Register from './component/Register';
import Head from './component/Head.js';
import Carousal from './component/Carousal.js';
import Header from './component/Header.js';
import Why from './component/Why.js';



import About from './pages/About.js';
import Gallery from './pages/Gallery.js';
import Staff from './pages/Staff.js';




import Data from './data/Data.js';
import Details from './data/Details.js';
import Upload from './data/Upload.js';
import BlogList from './data/BlogList.js';
import User from './data/User.js';



function App() {


  return (
    <div className='main'>

      <Router>
        <Header/>
        <Head />
        <Navbar />
        <Routes >
          
          <Route path="*" element={<Home />} />
          <Route path="/Register" element={<Register />} />
          <Route path="/Carousal" element={<Carousal />} />
          <Route path="/About" element={<About />} />
          <Route path="/Gallery" element={<Gallery />} />
          <Route path="/Why" element={<Why />} />
          <Route path="/Staff" element={<Staff />} />

          
          <Route path="/Data" element={<Data />} />
          <Route path="data/view/:id" element={<Details />} />
          <Route path="/Upload" element={<Upload />} />
          <Route path="/BlogList" element={<BlogList />} />
          <Route path="/User" element={<User />} />

        </Routes>
      </Router>

    </div>
  );
}

export default App;
