import React, { useState } from 'react';



const Head = () => {
   
    return (
        <div className='head'>
            <div className='head2'>
                <p>Managing Director: 91 9506133392</p>
            </div>
            <div className='head2'>
                <p>Doctor Contact Number: +91 9506133392</p>
            </div>
            <div className='head2'>
                <p>Chamber:1, Patel Nagar, Adarsh Nagar, Alambagh, Lucknow, Uttar Pradesh 226005</p>
            </div>
            
          
        </div>
    );
};

export default Head;
