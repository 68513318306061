import React from 'react'

const Why = () => {
    return (
        <>
            <div className='why'>
                <h1>WHY CHOOSE US</h1>

                <div className='whys2'>

                    <div className='agency'>

                        <div className='agency2'>
                            <h1>All Orthopedic  Services</h1>
                            <p>There are generally two types of orthopedists: surgical and nonsurgical. The former are called orthopedic surgeons, while nonsurgical orthopedists include physiatrists and physical medicine and rehabilitation specialists.</p>
                        </div>
                        <div className='agency2'>
                            <h1>Joint Replacement Surgery</h1>
                            <p>Joint replacement surgery is a procedure in which a surgeon removes a damaged joint and replaces it with a new, artificial part. </p>
                        </div>

                    </div>

                    <div className='agency'>

                        <div className='agency2'>
                            <h1>Safe Treatment</h1>
                            <p> the action or way of treating a patient or a condition medically or surgically : management and care to prevent, cure, ameliorate, or slow progression of a medical condition. treatment of accident victims.</p>
                        </div>
                        <div className='agency2'>
                            <h1>Specialist  Of Doctors</h1>
                            <p> He trained in trauma and joint replacement surgery at several esteemed institutions in India, and, with more than 25 years of experience.</p>
                        </div>

                    </div>

                </div>


            </div>

        </>
    )
}

export default Why