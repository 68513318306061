import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import XIcon from '@mui/icons-material/X';
import logo from "./images/logo (1).jpg";

const Navbar = () => {
    const [expanded, setExpanded] = useState(false);

    const handleNavItemClick = () => {
        setExpanded(false);
    };

    return (
        <>
            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid">
                    <Link to="/" className="navbar-brand" >
                        <img src={logo} alt="Logo" />
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded={expanded} aria-label="Toggle navigation" onClick={() => setExpanded(!expanded)}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={`collapse navbar-collapse ${expanded ? 'show' : ''}`} id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link to="/" className="nav-link" onClick={handleNavItemClick}>Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/About" className="nav-link" onClick={handleNavItemClick}>About</Link>
                            </li>


                            <li className="nav-item dropdown">
                                <Link to="#" className="nav-link dropdown-toggle" id="navbarDropdown2" role="button" data-bs-toggle="dropdown" aria-expanded="false">Treatment</Link>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown2">
                                    <li><Link to="/HipResurfacing" className="dropdown-item" onClick={handleNavItemClick}>Hip Resurfacing</Link></li>
                                    <li><Link to="/LigamentReconstruction" className="dropdown-item" onClick={handleNavItemClick}>Ligament Reconstruction</Link></li>
                                    <li><Link to="/Arthroscopy" className="dropdown-item" onClick={handleNavItemClick}>Arthroscopy</Link></li>
                                    <li><Link to="/HipReplacement" className="dropdown-item" onClick={handleNavItemClick}>Hip Replacement</Link></li>
                                    <li><Link to="/ShoulderReplacement" className="dropdown-item" onClick={handleNavItemClick}>Shoulder Replacement</Link></li>
                                    <li><Link to="/KneeReplacement" className="dropdown-item" onClick={handleNavItemClick}>Knee Replacement</Link></li>
                                    <li><Link to="/KneeOsteotomy" className="dropdown-item" onClick={handleNavItemClick}>Knee Osteotomy</Link></li>
                                    <li><Link to="/SpinalTherapy" className="dropdown-item" onClick={handleNavItemClick}>Spinal Therapy</Link></li>
                                    <li><Link to="/SpinalFusion" className="dropdown-item" onClick={handleNavItemClick}>Spinal Fusion</Link></li>
                                </ul>
                            </li>

                            {/* <li className="nav-item">
                                <Link to="/Staff" className="nav-link" onClick={handleNavItemClick}>Our Staff</Link>
                            </li> */}

                            <li className="nav-item">
                                <Link to="/Gallery" className="nav-link" onClick={handleNavItemClick}>Gallery</Link>
                            </li>



                            <li className="nav-item">
                                <Link to="/Data" className="nav-link" onClick={handleNavItemClick}>View Data</Link>
                            </li>



                            <li className="nav-item">
                                <Link to="/Register" className="nav-link" onClick={handleNavItemClick}> Appointment</Link>
                            </li>

                        </ul>


                    </div>
                </div>
            </nav>
        </>
    );
};

export default Navbar;
