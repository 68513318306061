import React from 'react'
import Footer from '../component/Footer'


const Staff = () => {
    return (
        <>


            <div className='heading'>
                <h1>Home / Our Doctors </h1>
            </div>
            <div className="staff-container">
                <div className="doctor-card">
                    <h2>Dr A. K. Goel</h2>
                    <p>M.B.B.S M.D (Medicine)</p>
                    <p>KGMU Lucknow</p>
                    <p>Consultant- Ex- Resident Dr. (D.D.U Hospital Delhi)</p>
                    <p>Ex-Chief Senior Resident, Pulmonary, & Critical Care Medicine K.G.M.U. Lucknow</p>
                </div>
                <div className="doctor-card">
                    <h2>Dr. Sonali Sharma</h2>
                    <p>M.B.B.S M.D</p>
                    <p>Obstetric Gynecology</p>
                    <p>Ex-Consultant Queen Mary Hospital KGMU Lucknow</p>
                </div>
                <div className="doctor-card">
                    <h2>Dr Vibhor Mahendru</h2>
                    <p>M.B.B.S, M.S, D.N.B</p>
                    <p>Senior Cancer Specialist</p>
                </div>
                <div className="doctor-card">
                    <h2>Dr. Amir Afroz Khan</h2>
                    <p>M.B.B.S, M.S, FICP, FIAGES</p>
                    <p>General and Laparoscopic Surgeon</p>
                </div>
                <div className="doctor-card">
                    <h2>Dr Bhawan Nangarwal</h2>
                    <p>M.B.B.S, M.S, Mch Neurosurgery SGPGI</p>
                    <p>Senior Consultant Neurosurgeon</p>
                </div>
                <div className="doctor-card">
                    <h2>Dr. R.K Pandey</h2>
                    <p>M.B.B.S, M.D (Respiratory Medicine) PDCC International Pulmonology</p>
                    <p>Allergy Asthma Specialist</p>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Staff
