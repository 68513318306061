import React, { useEffect, useState } from 'react'
import CreateIcon from '@mui/icons-material/Create';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WorkIcon from '@mui/icons-material/Work';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import { Link, NavLink } from 'react-router-dom';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import male from '../component/img/s1.jpg';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useParams } from 'react-router-dom';

const Details = () => {

  const [getuserdata, setuserdata] = useState([]);
  console.log(getuserdata);

  const { id } = useParams("");
  console.log(id);

  const getdata = async () => {
    const res = await fetch(`/getuser/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    }

    );
    const data = await res.json();
    console.log(data);
    if (!data || res.status === 404) {
      alert("No record avalable");
      console.log("error")
    }
    else {
      setuserdata(data);
      console.log(data)
      console.log("Data has been retrive");
    }
  }

  useEffect(() => {
    getdata();
  }, []);

  const deleteuser = async (id) => {
    const res2 = await fetch(`/deleteuser/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json"
      }
    });
    const deletedata = await res2.json();
    console.log(deletedata);
    if (res2.status === 404 || !deletedata) {
      console.log("error")
    }
    else {
      console.log("user Deleted");

    }
  }

  return (
    <div className="container mt-3" >
      <center> <h1 style={{ fontWeight: '400' }}>Appointment  Details</h1></center>
      <Card style={{ maxwidth: '600px' }}>

        <CardContent style={{ maxwidth: '600px' }}>
          {/* <div className='add_btn ml-5 '>
            <NavLink to={`/edit/${getuserdata._id}`}> <button className='btn  btn-primary'><CreateIcon /></button></NavLink>
            <NavLink> <button className='btn  btn-danger' onClick={() => deleteuser(getuserdata._id)}><DeleteOutlineIcon /></button></NavLink>
          </div> */}

          <div className="row">
            <div className='left-view col-lg-6 col-md-6 col-12' >
              
              <p className='mt-3'> <DriveFileRenameOutlineIcon /><b> Patient Name</b> <span >- {getuserdata.name}</span></p>
              <p className='mt-3'><CalendarMonthIcon /><b> Patient Age</b> <span >- {getuserdata.age}</span></p>
              <p className='mt-3'><MailOutlineIcon /> <b> PatientEmail</b> - <span>{getuserdata.email}</span></p>
              <p className='mt-3'><WorkIcon /> <b> Appointment Date</b>-  <span>{getuserdata.date}</span></p>


              
            </div>





            <div className='Right-view col-lg-6 col-md-6 col-12'>

              
            
              <p className='mt-4'><PhoneAndroidIcon /><b> Patient Mobile</b> -<span>{getuserdata.mobile}</span></p>
              <p className='mt-4'><LocationOnIcon /><b> Patient Location</b> -<span>{getuserdata.address}</span></p>
              <p className='mt-4'> <SubtitlesIcon /><b>Discreption</b> - <span>{getuserdata.description} </span></p>


            </div>
          </div>


        </CardContent>
      </Card>

    </div>
  )
}

export default Details